@tailwind base;
@tailwind components;
@tailwind utilities;

body{
	margin: 0;
	padding: 0;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Loader Animation */
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid yellow;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* shaking of cart */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(5px); }
}

.shake {
  animation: shake 0.5s ease-in-out;
}

a {
	color: #4d4d4d;
	text-decoration: none;
}

.fb a, .fb:before, .fb { background: #3b5999; color: #3b5999; }
.tw a, .tw:before, .tw { background: #55acee; color: #55acee; }
.in a, .in:before, .in { background: #e4405f; color: #e4405f; }
.gh a, .gh:before, .gh { background: #333333; color: #333333; }



ul#buttons {
	padding: 0;
	margin: 0;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	list-style: none;
	/* display: grid;
	grid-template-columns: repeat(4,1fr); */
  display: flex;
  gap: 0rem 2rem;
}


ul#buttons li {
	width: 50px;
	height: 50px;
	line-height: 60px;
	text-align: center;
	box-sizing: border-box;
	background: transparent;
	border-radius: 12px;
	position: relative;
	overflow: hidden;
	transition: .5s;
	box-shadow: 0px 8px 16px -6px,
				0px 0px 16px -6px;
}

ul#buttons li a {
	display: block;
	widows: 100%;
	height: 100%;
	font-size: 1.25em;
	background: transparent;
	transition: .5s;
	animation: icon-out .5s forwards;
	animation-timing-function: cubic-bezier(0.5, -0.6, 1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

ul#buttons li:before {
	content: "";
	width: 90px;
	height: 90px;
	display: block;
	position: absolute;
	transform: rotate(-45deg) translate(-110%, -23px);
	z-index: -2;
	animation: back-out .5s forwards;
	animation-timing-function: cubic-bezier(0.5, -0.6, 1, 1);
}

ul#buttons li:hover a {
	animation: icon-in .5s forwards;
	animation-timing-function: cubic-bezier(0, 0, 0.4, 1.6);
}

ul#buttons li:hover:before { 
	animation: back-in .5s forwards;
	animation-timing-function: cubic-bezier(0, 0, 0.4, 1.6);
}

@keyframes back-in {
    0% { transform: rotate(-45deg) translate(-110%, -23px); }
	80% { transform: rotate(-45deg) translate(5%, -23px); }
	100% { transform: rotate(-45deg) translate(0%, -23px); }
}

@keyframes back-out {
    0% { transform: rotate(-45deg) translate(0%, -23px); }
	20% { transform: rotate(-45deg) translate(5%, -23px); }
	100% { transform: rotate(-45deg) translate(-110%, -23px); }
}

@keyframes icon-in {
    0% { font-size: 1.25em; }
	80% { color: #fff; font-size: 1.5em; }
	100% { color: #fff; font-size: 1.35em; }
}

@keyframes icon-out {
	0% { color: #fff; font-size: 1.35em; }
	20% { color: #fff; font-size: 1.5em; }
	100% { font-size: 1.25em; }
}

.ninthcontainer {
	width: 100%; /* Adjust the width as needed */
	height: 500px; /* Adjust the height as needed */
  }
#particles-js {
    display: flex;
    vertical-align: bottom;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed	
}
#particles-js .particles-js-canvas-el {
    width: 100vw !important;
    height: 100vh !important
}

.slider{
	/* top: 50% !important;
	left: 50% !important; 
	transform: translate(-50%, -50%) !important; */
	/* padding: 110px !important; */
}